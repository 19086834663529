import SimpleForm from "../../components/SimpleForm"
import ApiService from "../../services/api-service"
import DurationInput from "../../components/DurationInput";

export default {
    name: "PlaythroughEdit",
    components: {DurationInput, SimpleForm},
    data() {
        return {
            playthrough: {
                id: null,
                newGame: true,
                newGameInfo: {
                    name: null,
                    platformId: null,
                    genreId: null
                },
                gameId: null,
                rate: null,
                difficulty: null,
                regionId: null,
                duration: null,
                status: null,
                startDate: null,
                endDate: null,
                pickedBy: null,
                projectId: null,
                streamNumber: null,
                streamCount: null,
                firstPlaythrough: null,
                blind: null,
                comment: null,
                vods: []
            },
            games: [],
            platforms: [],
            genres: [],
            regions: [],
            projects: [],
            firstPlaythroughItems: [],
            blindItems: [],
            vodTypes: [],
            statuses: [],
            hardwareTypes: []
        }
    },
    methods: {
        addVodPart(vodType) {
            let vod = this.playthrough.vods.find(vod => vod.type === vodType);

            if (!vod) {
                vod = {
                    type: vodType,
                    parts: []
                }
                this.playthrough.vods.push(vod)
            }

            let parts = vod.parts.map(part => part.ordinal)
            let ordinal = Math.max(...parts)
            if (!isFinite(ordinal)) {
                ordinal = 0
            } else {
                ordinal = ordinal + 1
            }

            vod.parts.push({
                ordinal: ordinal,
                url: null
            })
        },
        minusVodPart(vodType, part) {
            let vod = this.playthrough.vods
                .find(v => v.type === vodType)

            vod.parts = vod.parts.filter(p => p !== part)
            let ordinal = 0
            vod.parts.forEach(p => {
                p.ordinal = ordinal++
            })
        },
        findVodParts(vodType) {
            let vod = this.playthrough.vods
                .find(v => v.type === vodType)

            if (!vod) {
                return []
            } else {
                return vod.parts
            }
        }
    },
    mounted() {
        ApiService.get("admin/items/games")
            .then(response => {
                this.games = response.data
            })
        ApiService.get("admin/items/platforms")
            .then(response => {
                this.platforms = response.data
            })
        ApiService.get("admin/items/genres")
            .then(response => {
                this.genres = response.data
            })
        ApiService.get("admin/items/regions")
            .then(response => {
                this.regions = response.data
            })
        ApiService.get("admin/items/projects")
            .then(response => {
                this.projects = response.data
            })
        ApiService.get("admin/items/first-playthrough")
            .then(response => {
                this.firstPlaythroughItems = response.data
            })
        ApiService.get("admin/items/blind")
            .then(response => {
                this.blindItems = response.data
            })
        ApiService.get("admin/items/vod-types")
            .then(response => {
                this.vodTypes = response.data
            })
        ApiService.get("admin/items/statuses")
            .then(response => {
                this.statuses = response.data
            })
        ApiService.get("admin/items/hardware-types")
            .then(response => {
                this.hardwareTypes = response.data
            })
    }
}