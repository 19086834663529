<template>
  <div>
    <b-row>
      <b-col cols="auto">
        <b-button variant="primary" @click="updatePlaythroughStatuses">
          Починить статусы прохождений
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="createDropmaniaMapPoints">
          Добавить клетки Дропмании 4.0
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="createDropmaniaPlayers">
          Добавить игроков Дропмании 4.0
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="fixJournal">
          Пофиксить журнал
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="initRgg">
          Инициализировать РГГ
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="initDropmania5">
          Инициализировать Дропманию 5
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="initDropmania5TestData">
          Инициализировать Тестовые данные Дропмании 5
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="fixRegions">
          Фикс Регионов
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiService from "../../services/api-service";

export default {
  name: "Fix",
  methods: {
    updatePlaythroughStatuses() {
      ApiService.put("admin/fix/update-playthrough-statuses")
    },
    createDropmaniaMapPoints() {
      ApiService.post("admin/fix/create-dropmania-map-points")
    },
    createDropmaniaPlayers() {
      ApiService.post("admin/fix/create-dropmania-players")
    },
    fixJournal() {
      ApiService.post("admin/fix/fix-journal")
    },
    initRgg() {
      ApiService.post("admin/fix/init-rgg")
    },
    initDropmania5() {
      ApiService.post("admin/fix/init-dropmania5")
    },
    initDropmania5TestData() {
      ApiService.post("admin/fix/init-dropmania5-test-data")
    },
    fixRegions() {
      ApiService.post("admin/fix/fix-regions")
    },
  }
}
</script>
<style scoped></style>