import ApiService from "../../services/api-service";
import VodIcon from "../../components/VodIcon";

export default {
    name: "CompletedByPlatform",
    components: {VodIcon},
    data(){
        return {
            loading: false,
            platforms: [],
            fields: [
                {key: "index", label: "№"},
                {key: "gameName", label: "Название", sortable: true},
                {key: "genreName", label: "Жанр", sortable: true},
                {key: "region", label: "Регион", sortable: true},
                {key: "duration", label: "Время", sortable: true},
                {key: "endDate", label: "Пройдено", sortable: true},
                {key: "rate", label: "Рейтинг", sortable: true},
                {key: "difficulty", label: "Сложность", sortable: true},
                {key: "pickedBy", label: "Выбрал", sortable: true},
                {key: "vodParts", label: "Ссылки на воды", tdClass: "vods"}
            ]
        }
    },
    computed: {
        totalGames: function(){
            return this.platforms.reduce((sum, platform) => sum + platform.games.length, 0)
        }
    },
    methods: {
        getData() {
            this.loading = true
            ApiService.get("completed/by-platform").then(response => {
                this.platforms = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    },
    mounted() {
        this.getData()
    }
}