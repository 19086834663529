import { render, staticRenderFns } from "./CompletedList.vue?vue&type=template&id=2dcd4e0c&scoped=true"
import script from "./completed-list.js?vue&type=script&lang=js&external"
export * from "./completed-list.js?vue&type=script&lang=js&external"
import style0 from "./CompletedList.vue?vue&type=style&index=0&id=2dcd4e0c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dcd4e0c",
  null
  
)

export default component.exports